import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import AppleIcon from '@mui/icons-material/Apple';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import google from '../../../../public/google.svg';
import { COLORS } from '../../../lib/colors';
import { SecondaryButton } from '../../buttons/SecondaryButton';
import { ButtonText } from '../../typography';

const StyledEmailButton = styled(Button)(({ theme }) => ({
  height: 54,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 27,
  border: `1px solid ${theme.palette.primary.main}`,
  padding: '15px',
  marginBottom: '16px',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const SocialButtonWhiteText = styled(ButtonText)({
  color: 'white',
  textTransform: 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const StyledSocialLoginButton = styled(SecondaryButton)({
  maxWidth: '50rem',
});

interface SocialLoginButtonProps {
  onSocialLogin: (provider: CognitoHostedUIIdentityProvider) => Promise<void>;
}

interface EmailLoginButtonProps {
  onClick: () => void;
}

export const FacebookButton: React.FC<SocialLoginButtonProps> = ({ onSocialLogin }) => {
  const { t } = useTranslation('login');
  return (
    <StyledSocialLoginButton
      fullWidth
      onClick={() => {
        void onSocialLogin(CognitoHostedUIIdentityProvider.Facebook);
      }}
      icon={<FacebookOutlinedIcon sx={{ width: '2.4rem', height: '2.4rem' }} />}
      sx={{
        color: '#1877F2',
      }}
    >
      {t('continueWithFacebook', 'Continue with Facebook')}
    </StyledSocialLoginButton>
  );
};

export const GoogleButton: React.FC<SocialLoginButtonProps> = ({ onSocialLogin }) => {
  const { t } = useTranslation('login');
  return (
    <StyledSocialLoginButton
      fullWidth
      onClick={() => {
        void onSocialLogin(CognitoHostedUIIdentityProvider.Google);
      }}
      icon={<Image src={google} alt={'Continue with Google'} />}
    >
      {t('continueWithGoogle', 'Continue with Google')}
    </StyledSocialLoginButton>
  );
};

export const AppleButton: React.FC<SocialLoginButtonProps> = ({ onSocialLogin }) => {
  const { t } = useTranslation('login');
  return (
    <StyledSocialLoginButton
      fullWidth
      onClick={() => {
        void onSocialLogin(CognitoHostedUIIdentityProvider.Apple);
      }}
      icon={<AppleIcon sx={{ width: '2.4rem', height: '2.4rem' }} />}
    >
      {t('continueWithApple', 'Continue with Apple')}
    </StyledSocialLoginButton>
  );
};

export const EmailButton: React.FC<EmailLoginButtonProps> = ({ onClick }) => {
  const { t } = useTranslation('login');
  return (
    <StyledEmailButton variant="contained" onClick={() => onClick()}>
      <SocialButtonWhiteText>
        <MailOutlineIcon />
        {t('registerWithEmail', 'Register with email')}
      </SocialButtonWhiteText>
    </StyledEmailButton>
  );
};

export const OrDivider = styled(Divider)({
  fontSize: '1.2rem',
  lineHeight: '1.6rem',
  color: COLORS.black_38,
  paddingLeft: '6rem',
  paddingRight: '6rem',
  width: '100%',
});
