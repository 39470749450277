import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Caption, Tab } from './typography/legacy/Text';
import { COLORS } from '../lib/colors';

type FooterProps = {
  termsHref?: string;
  privacyPolicyHref?: string;
  supportEmail?: string;
};

const StyledTab = styled(Tab)(() => ({
  display: 'inline',
}));

const StyledCaption = styled(Caption)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? COLORS.O4MeDarkGray : COLORS.O4MeLightGray,
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  marginTop: 'auto',
  textAlign: 'center',
}));

const LinksContainer = styled(Box)(({ theme }) => ({
  marginRight: 'auto',
  marginLeft: 'auto',
  marginBottom: theme.spacing(4),
}));

const LinkMode = styled(Link)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? 'white' : theme.palette.text.secondary,
}));

export const Footer: React.FC<FooterProps> = ({
  termsHref = 'https://static.outcomes4me.com/termsofservice/',
  privacyPolicyHref = 'https://static.outcomes4me.com/privacy/',
  supportEmail = 'support@outcomes4me.com',
}) => {
  const { t } = useTranslation('footer');

  return (
    <FooterContainer>
      <LinksContainer>
        <StyledTab>
          <LinkMode href="https://outcomes4me.com" target="_blank" underline="hover">
            {t('aboutOutcomes4me', 'About Outcomes4Me')}
          </LinkMode>
        </StyledTab>
        <StyledTab>&nbsp;&nbsp;•&nbsp;&nbsp;</StyledTab>
        <StyledTab>
          <LinkMode href={termsHref} target="_blank" underline="hover">
            {t('termsOfService', 'Terms of Service')}
          </LinkMode>
        </StyledTab>
        <StyledTab>&nbsp;&nbsp;•&nbsp;&nbsp;</StyledTab>
        <StyledTab>
          <LinkMode href={privacyPolicyHref} target="_blank" underline="hover">
            {t('privacyPolicy', 'Privacy Policy')}
          </LinkMode>
        </StyledTab>
        <StyledTab>&nbsp;&nbsp;•&nbsp;&nbsp;</StyledTab>
        <StyledTab>
          <LinkMode href={`mailto:${supportEmail}`} target="_blank" underline="hover">
            {t('giveFeedback', 'Give Feedback')}
          </LinkMode>
        </StyledTab>
      </LinksContainer>
      {/* TODO: replace with ImageWithBasePath common component once piportal is upgraded to Next.js >= 13 */}
      <Image
        src={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/logo-4Me.png`}
        width={70}
        height={25}
        alt={t('outcomes4me', 'Outcomes4Me')}
      />
      <StyledCaption>
        {t('copyrightOutcomes4Me', {
          year: new Date().getFullYear(),
          defaultValue: '© {{year}} Outcomes4Me Inc. All rights reserved.',
        })}
      </StyledCaption>
    </FooterContainer>
  );
};

export default Footer;
