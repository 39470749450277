import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '@components/HeaderContent';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

type LayoutProps = {
  children: React.ReactNode;
};

export const Page = styled(Box)<LayoutProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: MOBILE_HEADER_HEIGHT,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    marginTop: HEADER_HEIGHT,
  },
}));

export const MainContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  rowGap: '10px',
  flexDirection: 'column',
  paddingBottom: '20px',
}));

export const WindowWrapper = styled(Grid)(() => ({
  display: 'flex',
  minHeight: '100vh',
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'column',
  },
}));

export const RowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
