import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';

import { materialIconPropMap } from './button.const';
import { ButtonProps } from './button.types';
import { COLORS } from '../../lib/colors';

const Button = styled(MuiButton)(({ theme, fullWidth }) => ({
  borderRadius: '100px',
  fontSize: '1.9rem',
  height: '5.6rem',
  border: '1px solid',
  lineHeight: '100%',
  fontWeight: '500',
  boxShadow: 'none',
  padding: '1.2rem 2.6rem',
  width: fullWidth ? '100%' : 'auto',
  '&&:disabled': {
    color: theme.palette.primary.contrastText,
  },
  '&&:hover': {
    backgroundColor: COLORS.O4M_ORANGE,
    boxShadow: 'none',
  },
  '&&:active': {
    backgroundColor: COLORS.O4M_ORANGE,
    boxShadow: 'none',
  },
}));

/**
 * O4M "Outlined" Button
 */
export const SecondaryButton: FC<ButtonProps> = ({
  className,
  fullWidth,
  icon,
  iconPlacement = 'left',
  disabled,
  onClick,
  style,
  children,
  ...props
}) => {
  return (
    <Button
      color="secondary"
      className={className}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      style={style}
      {...{ [materialIconPropMap[iconPlacement]]: icon }}
      {...props}
    >
      {children}
    </Button>
  );
};
