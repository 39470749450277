import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../../lib/i18n';
import { AuthStyledForm, AuthStyledTextField } from './useAuthFormStyles';
import { PrimaryButton } from '../../buttons/PrimaryButton';

interface CodeFormProps {
  onSubmit: (code: string) => void;
}

const CodeForm: React.FC<CodeFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation('login');
  const [code, setCode] = useState('');

  return (
    <Box>
      <AuthStyledForm
        onSubmit={(event) => {
          // Prevents the page from refreshing on submit
          event.preventDefault();
          onSubmit(code);
        }}
      >
        <AuthStyledTextField
          autoFocus
          required
          fullWidth
          id="code"
          label={t('code', 'Code')}
          type="text"
          variant="outlined"
          autoComplete="code"
          value={code}
          onChange={(event) => setCode(event.target.value)}
        />
        <PrimaryButton fullWidth color="secondary" type="submit">
          {t('enterCode', 'Enter code')}
        </PrimaryButton>
      </AuthStyledForm>
    </Box>
  );
};

export default CodeForm;
