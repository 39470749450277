import {
  Checkbox,
  FormControlLabel,
  Link,
  styled,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import get from 'lodash/get';
import React from 'react';
import {
  Control,
  Controller,
  FormState,
  Path,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../lib/colors';
import { UserLogin, UserRegistration } from '../../../lib/types';
import {
  has1Lowercase,
  has1Number,
  has1Uppercase,
  hasAtleastNCharacters,
  isAtleast18YearsAgo,
  isValidEmail,
} from '../../../lib/validators';
import { Caption } from '../../typography/Caption';

// Allowing any in certain cases:
// Disable the type errors for body2 caused by the use of mui/labs alpha having looser types than mui/system:
//  Type 'CSSProperties' is not assignable to type 'Properties<string | number, string & {}>'

interface FormProps<T extends UserLogin = UserRegistration> {
  register: UseFormRegister<T>;
  formState: FormState<T>;
  watch: UseFormWatch<T>;
}

interface EmailFormProps<T extends UserLogin = UserRegistration> extends FormProps<T> {
  autofillFields?: Partial<T>;
}

interface ControlProps {
  control: Control<UserRegistration, unknown>;
}

const AgreementFormControlLabel = styled(FormControlLabel)({
  alignItems: 'center',
});

const AgreementCaption = styled(Caption)({
  textAlign: 'left',
  fontSize: '1.4rem',
  lineHeight: 1.6,
  marginTop: 8,
});

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiInput-underline:before': {
    borderBottomColor: theme.palette.divider,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.divider,
    },
  },
}));

const CheckboxAnswer = styled(Checkbox)(({ theme }) => ({
  marginRight: 0,
  marginLeft: 0,
  '& .MuiSvgIcon-root': { fontSize: 24 },
  borderRadius: '100px',
  fontSize: '1.9rem',
  '&&:disabled': {
    color: theme.palette.primary.contrastText,
  },
  '&&:hover': {
    backgroundColor: COLORS.O4M_LIGHT_CREAM,
    borderColor: theme.palette.secondary.light,
    boxShadow: 'none',
  },
  '&&:active': {
    backgroundColor: COLORS.O4M_LIGHT_CREAM,
    borderColor: theme.palette.secondary.dark,
    boxShadow: 'none',
  },
}));

export const FirstNameField: React.FC<FormProps> = ({ register, formState, watch }) => {
  const { t } = useTranslation('signUp');
  const theme = useTheme();
  return (
    <StyledTextField
      required
      fullWidth
      id="firstName"
      label={t('firstName', 'First name')}
      variant="outlined"
      autoComplete="given-name"
      error={Boolean(formState.errors?.firstName)}
      // Disable the following error caused by the use of mui/labs alpha having looser types than mui/system:
      //  Type 'CSSProperties' is not assignable to type 'Properties<string | number, string & {}>'
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      InputProps={{ style: theme.typography.body2 } as { style: any }}
      // forces the placeholder label to shrink up so it doesn't overlap with input
      InputLabelProps={{
        // Disable the following error caused by the use of mui/labs alpha having looser types than mui/system:
        //  Type 'CSSProperties' is not assignable to type 'Properties<string | number, string & {}>'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        style: theme.typography.body2 as any,
        shrink: !!watch('firstName'),
      }}
      helperText={<span>{get(formState, 'errors.firstName.message', '')}</span>}
      {...register('firstName', {
        required: t('First name is required', 'First name is required.'),
      })}
    />
  );
};

export const LastNameField: React.FC<FormProps> = ({ register, formState, watch }) => {
  const { t } = useTranslation('signUp');
  const theme = useTheme();
  return (
    <StyledTextField
      required
      fullWidth
      id="lastName"
      label={t('lastName', 'Last name')}
      variant="outlined"
      autoComplete="family-name"
      error={Boolean(formState.errors?.lastName)}
      helperText={<span>{get(formState, 'errors.lastName.message', '')}</span>}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      InputProps={{ style: theme.typography.body2 as any }}
      // Disable the following error caused by the use of mui/labs alpha having looser types than mui/system:
      //  Type 'CSSProperties' is not assignable to type 'Properties<string | number, string & {}>'
      // forces the placeholder label to shrink up so it doesn't overlap with input
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      InputLabelProps={{ style: theme.typography.body2 as any, shrink: !!watch('lastName') }}
      {...register('lastName', {
        required: t('Last name is required', 'Last name is required.'),
      })}
    />
  );
};

export function EmailField<T extends UserLogin = UserRegistration>({
  register,
  formState,
  autofillFields,
  watch,
}: EmailFormProps<T>) {
  const { t } = useTranslation('signUp');
  const theme = useTheme();
  return (
    <StyledTextField
      required
      fullWidth
      id="email"
      label={t('emailAddress', 'Email address')}
      type="email"
      variant="outlined"
      autoComplete="username"
      defaultValue={autofillFields?.email}
      error={Boolean(formState.errors?.email)}
      helperText={<span>{get(formState, 'errors.email.message', '')}</span>}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      InputProps={{ style: theme.typography.body2 as any }}
      // forces the placeholder label to shrink up so it doesn't overlap with input
      InputLabelProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        style: theme.typography.body2 as any,
        shrink: !!watch('email' as Path<T>),
      }}
      {...register('email' as Path<T>, {
        required: t('Email is required', 'Email is required.'),
        validate: {
          isValidEmail,
        },
      })}
    />
  );
}

export function PasswordField<T extends UserLogin = UserRegistration>({
  register,
  formState,
  validate,
  watch,
}: FormProps<T> & { validate?: boolean }) {
  const { t } = useTranslation('signUp');
  const theme = useTheme();
  return (
    <StyledTextField
      required
      fullWidth
      id="password"
      label={t('password', 'Password')}
      type="password"
      variant="outlined"
      autoComplete="new-password"
      error={Boolean(formState.errors?.password)}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      InputProps={{ style: theme.typography.body2 as any }}
      // forces the placeholder label to shrink up so it doesn't overlap with input

      InputLabelProps={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        style: theme.typography.body2 as any,
        shrink: !!watch('password' as Path<T>),
      }}
      {...register('password' as Path<T>, {
        required: t('Password is required', 'Password is required.'),
        validate: validate
          ? {
              hasAtleast8Characters: (val) => hasAtleastNCharacters(val, 8),
              has1Number,
              has1Lowercase,
              has1Uppercase,
            }
          : undefined,
      })}
    />
  );
}

export const BirthyearField: React.FC<FormProps> = ({ register, formState, watch }) => {
  const { t } = useTranslation('signUp');
  const theme = useTheme();
  return (
    <StyledTextField
      required
      fullWidth
      id="birthYear"
      label={t('birth year', 'Birth year')}
      type="number"
      variant="outlined"
      autoComplete="birth-year"
      error={Boolean(formState.errors?.birthYear)}
      helperText={<span>{get(formState, 'errors.birthYear.message', '')}</span>}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      InputProps={{ style: theme.typography.body2 as any }}
      // forces the placeholder label to shrink up so it doesn't overlap with input
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      InputLabelProps={{ style: theme.typography.body2 as any, shrink: !!watch('birthYear') }}
      {...register('birthYear', {
        required: t('Birth year is required', 'Birth year is required.'),
        validate: {
          has4Numbers: (val: number) =>
            val >= 1000 || t('Birth year must be 4 numbers', 'Birth year must be 4 numbers.'),
          isAdult: (val) =>
            isAtleast18YearsAgo(val) || t('You must be over 18', 'You must be over 18.'),
        },
      })}
    />
  );
};

export const SignupController: React.FC<ControlProps> = ({ control }) => {
  const { t } = useTranslation('signUp');
  return (
    <Controller
      control={control}
      name="accepted_terms"
      rules={{ required: true }}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <AgreementFormControlLabel
          control={
            <CheckboxAnswer color="primary" onChange={onChange} checked={value as boolean} />
          }
          id="agreement"
          label={
            <AgreementCaption>
              {t('agreeTermsOfService', 'Agree to our')}{' '}
              <Link
                href="https://static.outcomes4me.com/termsofservice/"
                underline="hover"
                target="_blank"
              >
                {t('termsOfService', 'Terms of Service')}
              </Link>{' '}
              {t('and', 'and')}{' '}
              <Link
                href="https://static.outcomes4me.com/privacy/"
                underline="hover"
                target="_blank"
              >
                {t('privacyPolicy', 'Privacy Policy')}
              </Link>
            </AgreementCaption>
          }
        />
      )}
    />
  );
};
