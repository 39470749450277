import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import '../../../lib/i18n';
import { AuthStyledForm, AuthStyledTextField } from './useAuthFormStyles';
import { PrimaryButton } from '../../buttons/PrimaryButton';
import { PasswordMeter, PasswordRules } from '../PasswordMeter';

interface Props {
  onSubmit: (newPassword: string) => void;
  customPasswordRules?: PasswordRules;
}

const ResetPasswordForm: React.FC<Props> = ({ onSubmit, customPasswordRules }) => {
  const { t } = useTranslation('forgotPassword');
  const [newPassword, setNewPassword] = useState('');

  return (
    <Box>
      <AuthStyledForm
        onSubmit={(event) => {
          // Prevents the page from refreshing on submit
          event.preventDefault();
          onSubmit(newPassword);
        }}
      >
        <AuthStyledTextField
          autoFocus
          required
          id="newPassword"
          label={t('newPassword', 'New password')}
          type="password"
          variant="outlined"
          autoComplete="new-password"
          value={newPassword}
          onChange={(event) => setNewPassword(event.target.value)}
        />
        <PasswordMeter password={newPassword} customPasswordRules={customPasswordRules} />
        <PrimaryButton fullWidth color="secondary" type="submit">
          {t('resetPassword', 'Reset password')}
        </PrimaryButton>
      </AuthStyledForm>
    </Box>
  );
};

export default ResetPasswordForm;
