import HeaderContent from '@components/HeaderContent';
import { authenticationEvent, logAnalyticsEvent, mfaPageViewEvent } from '@lib/analyticsUtils';
import { ContentWrapper, MainContent, Page, WindowWrapper } from '@lib/layoutUtils';
import { loadLoggedOutOnlyPageProps } from '@lib/utils';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { captureException } from '@sentry/nextjs';
import Login from 'common/src/components/auth/Login';
import { Footer } from 'common/src/components/Footer';
import { GetServerSideProps, NextPage } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React from 'react';

const LoginWrapper = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
}));

const LoginPage: NextPage = () => {
  const router = useRouter();
  const userPoolId = process.env.NEXT_PUBLIC_PIPORTAL_COGNITO_USER_POOL_ID!;
  const userPoolWebClientId = process.env.NEXT_PUBLIC_PIPORTAL_COGNITO_CLIENT_ID!;

  const { t } = useTranslation('login');

  return (
    <>
      <HeaderContent />
      <WindowWrapper>
        <Page>
          <ContentWrapper>
            <MainContent>
              <LoginWrapper>
                <Login
                  userType="piportal"
                  userPoolId={userPoolId}
                  userPoolWebClientId={userPoolWebClientId}
                  authenticationFlowType="CUSTOM_AUTH"
                  onLoginSubmit={() => authenticationEvent('Click', 'Login')}
                  onSuccess={() => {
                    authenticationEvent('Success');
                    router.push({ pathname: '/' }).catch(captureException);
                  }}
                  forgotPasswordHref="/forgot-password"
                  customHeader={t(
                    'clinicalTrialsProviderPortal',
                    'Clinical Trials Provider Portal',
                  )}
                  subtitle={t('logInToYourAccount', 'Log in to your account')}
                  showMfaChoice
                  onSoftwareTokenMfaView={() => mfaPageViewEvent({ mfa_type: 'totp' })}
                  onSoftwareTokenMfaSubmit={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'VerifyMFACode',
                      action: 'Click',
                      properties: { mfa_type: 'totp' },
                    })
                  }
                  onSmsMfaView={() => mfaPageViewEvent({ mfa_type: 'sms' })}
                  onSmsMfaSubmit={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'VerifyMFACode',
                      action: 'Click',
                      properties: { mfa_type: 'sms' },
                    })
                  }
                  onEmailMfaView={() => mfaPageViewEvent({ mfa_type: 'email' })}
                  onEmailMfaSubmit={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'VerifyMFACode',
                      action: 'Click',
                      properties: { mfa_type: 'email' },
                    })
                  }
                  onBackToLoginClick={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'BackToLogin',
                      action: 'Click',
                    })
                  }
                  onMfaSetupView={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'MFASetup',
                      action: 'View',
                    })
                  }
                  onMfaSetupSubmit={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'MFASetup',
                      action: 'Click',
                    })
                  }
                  onSetNewPasswordView={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'SetNewPassword',
                      action: 'View',
                    })
                  }
                  onSetNewPasswordSubmit={() =>
                    logAnalyticsEvent({
                      feature: 'Authentication',
                      component: 'SetNewPassword',
                      action: 'Click',
                    })
                  }
                />
              </LoginWrapper>
            </MainContent>
          </ContentWrapper>
        </Page>
      </WindowWrapper>
      <Footer supportEmail="recruitmentsupport@outcomes4me.com" />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = loadLoggedOutOnlyPageProps();

export default LoginPage;
