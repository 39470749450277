import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EmailField, PasswordField } from './AuthComponents';
import { AppleButton, FacebookButton, GoogleButton, OrDivider } from './SocialLoginButtons';
import { AuthStyledForm } from './useAuthFormStyles';
import { AuthMethod, UserLogin } from '../../../lib/types';
import { PrimaryButton } from '../../buttons/PrimaryButton';
import { CaptionLink } from '../../Links/Link';

const SocialLoginWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '50rem',
  rowGap: '1.6rem',
});

interface SocialButtonsProps {
  onSocialLogin: (provider: CognitoHostedUIIdentityProvider) => Promise<void>;
  activePrimaryMethod?: AuthMethod;
  primaryMethod?: AuthMethod;
  renderAll?: boolean;
}

const SocialButtons: React.FC<SocialButtonsProps> = ({
  onSocialLogin,
  activePrimaryMethod,
  primaryMethod,
}) => {
  const buttons: Record<string, React.ReactNode> = {
    google: <GoogleButton onSocialLogin={onSocialLogin} />,
    facebook: <FacebookButton onSocialLogin={onSocialLogin} />,
    apple: <AppleButton onSocialLogin={onSocialLogin} />,
  };

  if (!activePrimaryMethod && !primaryMethod) {
    return (
      <SocialLoginWrapper>{Object.keys(buttons).map((key) => buttons[key])}</SocialLoginWrapper>
    );
  }

  if (!activePrimaryMethod && primaryMethod) {
    return (
      <SocialLoginWrapper>
        {[
          buttons[primaryMethod],
          ...Object.keys(buttons).map((key) => key !== primaryMethod && buttons[key]),
        ]}
      </SocialLoginWrapper>
    );
  }

  if (activePrimaryMethod) {
    return <SocialLoginWrapper>{buttons[activePrimaryMethod]}</SocialLoginWrapper>;
  }
};

interface LoginFormProps {
  onSubmit: (formData: UserLogin) => void;
  onSocialLogin: (provider: CognitoHostedUIIdentityProvider) => Promise<void>;
  forgotPasswordHref: string;
  signUpHref?: string;
  showSocialButtons?: boolean;
  autofillFields?: Partial<UserLogin>;
  primaryMethod?: AuthMethod;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  onSocialLogin,
  forgotPasswordHref,
  signUpHref,
  autofillFields,
  primaryMethod,
  showSocialButtons = false,
}) => {
  const [activePrimaryMethod, setActivePrimaryMethod] = useState<AuthMethod | undefined>(
    primaryMethod,
  );
  useEffect(() => {
    setActivePrimaryMethod(primaryMethod);
  }, [primaryMethod]);
  const { register, formState, handleSubmit, watch, reset } = useForm<UserLogin>({
    defaultValues: autofillFields,
  });
  const { t } = useTranslation('login');

  // Reset form default values once autofillFields async loads
  useEffect(() => {
    reset(autofillFields);
  }, [reset, autofillFields]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <AuthStyledForm onSubmit={handleSubmit(onSubmit)}>
      {showSocialButtons && primaryMethod !== 'email' && (
        <>
          <SocialButtons
            onSocialLogin={onSocialLogin}
            activePrimaryMethod={activePrimaryMethod}
            primaryMethod={primaryMethod}
          />
          {!activePrimaryMethod && <OrDivider>{t('or', 'or')}</OrDivider>}
        </>
      )}
      {(!activePrimaryMethod || activePrimaryMethod === 'email') && (
        <>
          <EmailField
            register={register}
            formState={formState}
            autofillFields={autofillFields}
            watch={watch}
          />
          <PasswordField register={register} formState={formState} watch={watch} />
          <CaptionLink
            href={forgotPasswordHref}
            linkText={t('forgotPassword', 'Forgot your password?')}
          />
        </>
      )}
      {(!activePrimaryMethod || activePrimaryMethod === 'email') && (
        <PrimaryButton color="secondary" type="submit" disabled={formState.isSubmitting} fullWidth>
          {t('logIn', 'Log In')}
        </PrimaryButton>
      )}
      {signUpHref && (
        <CaptionLink
          href={signUpHref}
          captionText={t('firstTimeHere', 'First time here?')}
          linkText={t('createAnAccount', 'Create an account')}
        />
      )}
      {activePrimaryMethod && (
        <>
          <OrDivider>{t('or', 'or')}</OrDivider>
          <Box
            sx={{
              width: '100%',
              maxWidth: activePrimaryMethod !== 'email' ? '50rem' : undefined,
              textAlign: 'center',
            }}
          >
            <CaptionLink
              onClick={() => setActivePrimaryMethod(undefined)}
              linkText={t('otherLoginOptions', 'View other login options')}
            />
          </Box>
        </>
      )}
      {showSocialButtons && primaryMethod === 'email' && (
        <>
          {!activePrimaryMethod && <OrDivider>{t('or', 'or')}</OrDivider>}
          <SocialButtons
            onSocialLogin={onSocialLogin}
            activePrimaryMethod={activePrimaryMethod}
            primaryMethod={primaryMethod}
          />
        </>
      )}
    </AuthStyledForm>
  );
};

export default LoginForm;
